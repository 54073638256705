import request from "../utils/request";

export function get_account_list(data: JSON){
    return request({
        url: "/getAccountList",
        method: "post",
        data: data
    });
}

export function get_account_detail(data: JSON){
    return request({
        url: "/getAccountDetail",
        method: "post",
        data: data
    });
}

export function save_account_detail(data: JSON){
    return request({
        url: "/saveAccountDetail",
        method: "post",
        data: data
    });
}